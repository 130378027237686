<template>
  <h2>Restaurants in Haute-Nendaz</h2>
  <section v-for="restaurant in restaurantsNendaz" :key="restaurant.id">
    <h3 class="restaurant-title">{{ restaurant.title }}</h3>
    <div class="restaurant">
      <div class="restaurant-text">
        <p class="restaurant-description">{{ restaurant.description }}</p>
      </div>
      <figure class="restaurant-figure">
        <img
          class="restaurant-image"
          :src="
            require(`../../assets/images/restaurant-images/${restaurant.image}.png`)
          "
          :alt="restaurant.alt"
        />
      </figure>
    </div>
  </section>
  <h2>Restaurants on the slopes</h2>
  <section v-for="restaurant in restaurantsSlopes" :key="restaurant.id">
    <h3 class="restaurant-title">{{ restaurant.title }}</h3>
    <div class="restaurant">
      <div class="restaurant-text">
        <p class="restaurant-description">{{ restaurant.description }}</p>
      </div>
      <figure class="restaurant-figure">
        <img
          class="restaurant-image"
          :src="
            require(`../../assets/images/restaurant-images/${restaurant.image}.png`)
          "
          :alt="restaurant.alt"
        />
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      imagePrelink: "../../assets/images/restaurant-images/",
      restaurantsNendaz: [
        {
          id: 0,
          title: "La Cantina-Cactus - Mexican €€-€€€",
          description:
            "This Mexican restaurant has a very extensive Tex-Mex menu with something for everyone, also suitable for children. Our personal favourites are the tapas platter, the nachos, the chicken or beef fajitas (there is also a vegetarian option), or the plates that let you combine some of these favourites. And, while you wait for your food, do not forget to try the delicious fresh cocktails. The service is very friendly and they create a cosy atmosphere with the open bar in the middle of the restaurant.",
          image: "cactus",
          alt: "Cactus platter",
        },
        {
          id: 1,
          title: "La Cabane – Swiss cuisine €€€",
          description:
            "La Cabane is a restaurant in the centre of Haute-Nendaz. There are several typical Swiss dishes on the menu, such as cheese fondue, raclette and Valais platter. If you prefer something else, they also have lovely salads, burgers and more. When you choose to have lunch at La Cabane, the terrace is very sunny. For dinner the interior of the chalet restaurant is very cosy, especially the first floor.",
          image: "la-cabane",
          alt: "La Cabane",
        },
        {
          id: 2,
          title: "Les Etagnes – International menu €€-€€€",
          description:
            "This friendly family restaurant is located at the bottom of the slopes of Tracouet. Therefore, it is a perfect place for après-ski in their pub ‘Cheers’ on the terrace, but also for a quick lunch. It’s a perfect restaurant for larger groups and families with small children, because this family restaurant makes everyone feel at home. The location of the restaurant makes it a great place for children to play outside, while the rest of the family can enjoy their food.",
          image: "les-etagnes",
          alt: "Les Etagnes",
        },
        {
          id: 3,
          title: "Chez Edith – Swiss cuisine €€€",
          description:
            "This restaurant is on the route from Nendaz to Siviez and therefore in the winter more accessible by car. In the summer beautiful hiking trails cross this chalet in the middle of the woods and close to a river, you should take a seat on the big terrace to relax for a while. A family favourite of ours is the Swiss fondue bourguignonne. A specialty of the kitchen is the freshly caught trout on the spot.",
          image: "chez-edith",
          alt: "Chez Edith",
        },
      ],
      restaurantsSlopes: [
        {
          id: 0,
          title: "Chalet Novelly (Siviez) – Swiss Cuisine €€-€€€",
          description:
            "This ski-in ski-out restaurant halfway through the Combatseline is a very quiet and relaxing place on the slopes. There are not many tables on the terrace, which makes it only more enjoyable. The little chalets around the terrace, make it a place where you can sit outside in the sun for a long time, without getting cold through the wind. You should definitely try the different delicious burgers, soups and salads. The portions are great and the service is very friendly. We would for sure recommend a stop here. ",
          image: "chalet-novelly",
          alt: "Chalet Novelly",
        },
        {
          id: 1,
          title: "Le Dahu (Verbier) – Swiss, French, Italian €€€",
          description:
            "Restaurant le Dahu is a favourite place for a lot of regulars in this ski resort. The owner knows frequent quests personally and always drops by for a little chat. It’s a very large restaurant with tables outside as well as inside and the view is gorgeous. The staff interact very well, which makes it possible to have a three-course menu within an hour, without it feeling rushed. The quality of the food is significant higher than in some other restaurants on the slopes. If we want to enjoy an extensive lunch with friends or family, this place is definitely our favourite. Tip: make a reservation in advance, otherwise you have to wait for a long time since this is a very popular spot. In addition, if you prefer a more affordable lunch, the bottom floor knows a take-out restaurant ‘Le Break Dahu’ with picnic-tables, with the same kitchen as the seated restaurant above.",
          image: "le-dahu",
          alt: "Le Dahu",
        },
        {
          id: 2,
          title: "Chalet Carlsberg (Verbier) – Italian €€€",
          description:
            "The location of this restaurant is great, in between slopes and the terrace is always in the sun. For the menu, you have the choice of different Italian dishes, some of them are from the brick oven. They play a relaxing music over the entire terrace and the view is beautiful. Tip: make a reservation in advance, otherwise you have to wait for a long time. ",
          image: "chalet-carlsberg",
          alt: "Chalet Carlsberg",
        },
        {
          id: 3,
          title: "La Marmotte (Verbier) – Swiss cuisine €€-€€€",
          description:
            "This is a great stop during a trip to la Tzoumaz. The route ‘Vallon d’Arby’ from Verbier to La Tzoumaz takes a couple of hours (for good skiers only), which means that you could use a break on this beautiful terrace with a different view on Verbier. For non-skiers, it’s a beautiful hike up the mountains and you can sled down. Try the fondues, meats and the amazing burgers.",
          image: "la-marmotte",
          alt: "La Marmotte",
        },
        {
          id: 3,
          title: "Les Chottes (Veysonnaz) – Swiss cuisine €€-€€€",
          description:
            "This old farm is beautifully converted into a large restaurant, with a lot of the old details preserved. The old cow bells still hang in the restaurant and they are rung when someone gives a tip. It has a very homely feel to it, with a big fireplace in the middle of the restaurant. Impressive is the large walk-through igloo the owners of the restaurant build to go to the back terrace of the restaurant. ",
          image: "les-chottes",
          alt: "Les Chottes",
        },
      ],
    };
  },
};
</script>

<style scoped>
.restaurant-description {
  width: 90%;
}

.restaurant {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* figure {
  margin-left: 20px;
} */

img {
  float: right;
  max-height: 100%;
  width: 300px;
}
</style>
