<template>
  <div
    class="embedsocial-hashtag"
    data-ref="355eec28851fc3bd0462b8e771b1ce45110f99d6"
  ></div>
</template>

<script>
(function(d, s, id) {
  var js;
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://embedsocial.com/cdn/ht.js";
  d.getElementsByTagName("head")[0].appendChild(js);
})(document, "script", "EmbedSocialHashtagScript");
</script>

<style scoped>
.es-free {
  display: none;
}
</style>
