<template>
  <header>
    <p v-if="userName" class="welcome-user-bar">Welcome {{ userName }}!</p>
    <p v-else class="welcome-user-bar"></p>
    <div class="header-style">
      <h1 class="header-title">Chalet Mimique</h1>
      <nav class="navigation">
        <div
          class="navigation-item"
          v-for="navItem in navigation"
          :key="navItem.id"
        >
          <router-link class="routerLink" :to="navItem.page">{{
            navItem.title
          }}</router-link>
          <div
            class="navigation-dropdown"
            v-for="subLink in navItem.subNavigation"
            :key="subLink.id"
          >
            <router-link class="dropdown-item routerLink" :to="subLink.page">{{
              subLink.title
            }}</router-link>
          </div>
        </div>
        <!-- <base-button link to="/auth">Login</base-button> -->
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    navigation() {
      return this.$store.getters["navigation"];
    },
    userName() {
      return this.$store.getters["auth/userName"];
    },
  },
  methods: {},
};
</script>

<style scoped>
header {
  width: 100%;
}

.welcome-user-bar {
  width: 100%;
  text-align: end;
  /* border: 1px solid black; */
}

.header-style {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  position: relative;
  /* border: 1px solid blue; */
}

.header-title {
  width: 29%;
  /* text-align: center; */
  /* border: 1px solid black; */
  /* font-size: 2rem; */
}

.navigation {
  width: 70%;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20%;
  /* transform: translateY(-50%); */
  right: 0;
  padding: 5px;
  height: 35px;
  /* background-color: #ddd; */
  /* border: 1px solid green; */
}

.navigation-item {
  width: 100%;
  /* background-color: #DDD; */
  text-align: center;
}

.routerLink {
  color: #183152;
  text-decoration: none;
  font-size: 1.3rem;
}

.router-link-active {
  color: #539ADB;
}

.routerLink:hover {
  color: #A67C2D;
}

/* .navigation-item:hover {
} */

.navigation-dropdown {
  display: none;
}

.navigation-item:hover .navigation-dropdown {
  display: flex;
  flex-direction: column;
  height: 20px;
  padding: 5px;
  /* background-color: #ddd; */
}

/* .navigation-dropdown:first-of-type {
  margin-top: 5px;
} */
</style>
