<template>
  <figure>
    <img
      src="../../assets/images/mimique-in-snow.jpeg"
      alt="Ski area of Quatre Vallees"
      class="mimique-in-snow-image"
    />
  </figure>
  <horizontal-line></horizontal-line>
  <div class="subtitle">
    <h2>A cozy chalet just above the authentic village of Haute Nendaz</h2>
  </div>
  <horizontal-line></horizontal-line>
  <div class="discover">
    <router-link
      :to="info.link"
      class="discover-item"
      v-for="info in information"
      :key="info.id"
    >
      <h3 class="discover-title">{{ info.text }}</h3>
      <img
        class="discover-image"
        :src="require('../../assets/images/' + info.img + '.jpg')"
        alt=""
      />
    </router-link>
  </div>
    <base-button id="redirectButton" @click="redirect"
      >See availability</base-button
    >
</template>

<script>
export default {
  computed: {
    information() {
      return this.$store.getters["further_information"];
    },
  },
  methods: {
    redirect() {
      window.open(
        "https://www.interhome.com/switzerland/valais/nendaz/holiday-house-mimique-ch1961.20.2/"
      );
    },
  },
};
</script>

<style scoped>
.mimique-in-snow-image {
  margin-top: 30px;
  float: left;
  position: relative;
  width: 100%;
  height: 50%;
}

.subtitle {
  text-align: center;
}

.discover {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  padding: 10px 0;
  /* column-gap: 10px; */
  /* row-gap: 10px; */
}

.discover-title {
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 1.5rem;
}

.discover-item {
  /* width: 90%; */
  text-align: center;
  display: block;
  color: #183152;
  text-decoration: none;
  font-size: 1.3rem;
}

.discover-item:hover {
  cursor: pointer;
}

.discover-image {
  border-radius: 50px;
  border: 2px solid black;
  width: 90%;
  /* opacity: 0.4; */
}

.discover-image:hover {
  opacity: 1;
}

#redirectButton {
  width: 50%;
  transform: translateX(50%);
}
</style>
