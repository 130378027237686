let timer;

export default {
  async signup(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "signup",
    });
  },
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },
  async auth(context, payload) {
    let mode = payload.mode;
    let userNameExtraDb;
    let url =
      "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD2H3GiSXx36UzqJxwPU-BIpUJkAuHvmH0";

    if (mode === "login") {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD2H3GiSXx36UzqJxwPU-BIpUJkAuHvmH0";
    }
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
        returnSecureToken: true,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.error.message || "Failed to authenticate!"
      );
      throw error;
    }

    if (mode === "signup") {
      const responseUser = await fetch(
        "https://mimique-her-default-rtdb.europe-west1.firebasedatabase.app//users.json",
        {
          method: "POST",
          body: JSON.stringify({
            userId: responseData.localId,
            userName: payload.name,
          }),
        }
      );

      userNameExtraDb = payload.name;

      const responseUserData = await responseUser.json();

      if (!responseUser.ok) {
        const error = new Error(
          responseUserData.error.message || "Failed to authenticate!"
        );
        throw error;
      }
    } else {
      const responseUser = await fetch(
        `https://mimique-her-default-rtdb.europe-west1.firebasedatabase.app/users.json`,
        {
          method: "GET",
        }
      );

      const responseUserData = await responseUser.json();

      for (let userData in responseUserData) {
        let userId = responseData.localId;
        if (responseUserData[userData].userId === userId) {
          userNameExtraDb = responseUserData[userData].userName;
        }
      }
    }

    const expiresIn = +responseData.expiresIn * 1000;
    // const expiresIn = 10000;
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem("token", responseData.idToken);
    localStorage.setItem("userId", responseData.localId);
    localStorage.setItem("userName", userNameExtraDb);
    localStorage.setItem("tokenExpiration", expirationDate);

    console.log(expiresIn);

    timer = setTimeout(function() {
      context.dispatch("autoLogout");
    }, expiresIn);

    context.commit("setUser", {
      name: userNameExtraDb,
      token: responseData.idToken,
      userId: responseData.localId,
    });
  },
  autoLogin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 5000) {
      return;
    }

    setTimeout(function() {
      context.dispatch("autoLogout");
    }, expiresIn);

    if (token && userId && userName) {
      context.commit("setUser", {
        name: userName,
        token: token,
        userId: userId,
      });
    }
  },
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("tokenExpiration");

    clearTimeout(timer);

    context.commit("setUser", {
      name: null,
      token: null,
      userId: null,
    });
  },
  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');
  }
};
