<template>
  <h2>Unfortunately we couldn't find the page you're looking for</h2>
  <base-button @click="goBack">To previous page</base-button>
  <base-button @click="goHome">Go back to home</base-button>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.back();
    },
    goHome() {
      this.$router.replace("/home");
    },
  },
};
</script>
