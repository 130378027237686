<template>
  <section>
    <h2>Summer activities</h2>
    <div class="text-plus-image">
      <div class="text">
        <p>
          Because the chalet is located at 1500 meters and on the sunny side of
          Wallis and Switserland, the summertime weather is lovely. The summer
          days are usually sunny and warm with temperatures between the low and
          high 20's with high UV-indexes. The nights are nice and cool and still
          lovely to sit outside with a blanket on.
        </p>
        <p>
          On the grand southern terrace there is a big umbrella which can
          provide sunshade when necessary for breakfast, lunch or dinner. On the
          west side of the chalet is a balcony with an exceptional view of the
          Rone Valley and the mountain ridge on the north side of the valley.
        </p>
        <p>
          On the north side of the chalet is a balcony which is great to sit on
          in the evening while exploring the night skies with a clear milky way
          along all of the stars. There is also a beautiful view on Sion with
          its castle and monastary, which are famous from the book The Da Vinci
          Code.
        </p>
        <p>
          For sun-loving early birds and late afternoon shadow-liking cocktail
          drinkers there is a cosey round terrace on the north-east side of the
          chalet, under birch and beech trees with peeks to the valley up to 30
          kilometers wide.
        </p>
      </div>
      <figure>
        <img
          src="../../assets/images/mimique-summer.jpg"
          alt="Mimique in summer"
        />
      </figure>
    </div>

    <h3>Cable cars and chair lifts</h3>
    <p>
      Nearly all year round you can take the cable cars and chair lifts to all
      the peaks and high altitude restaurants of Quatre Valley. A map is
      available at Tracouet and Siviez station. of course you can buy a ticket
      for one way or a return. There is also the possibility to purchase the
      Pass Altitude for 3 to 6 days.
    </p>

    <h3>BBQ at the brook</h3>
    <p>
      From spring to late autumn it is great to have a lunch or early dinner
      BBQat the Printse brook above Siviez ( Super Nendaz). It’s a 20 minute
      drive to reach the brook. When you reach Siviez follow the road to the
      last parking lot, then follow the road left over the wooden bridge and
      pass underneath the (ski-lift). Follow the road until you come to several
      parking places for your car above a BBQ area. There are several stone
      BBQ’s but you can also take the small BBQ, which you can find in the
      garage at Mimique. Children ( and elder) can play safely in the cold
      water. The meadow is great for sunbathing/ playing with a ball.
    </p>
    <p>
      Just place your beers and other drinks in the brook to keep them cold
      while you’re waiting for the food to get cooked. Another good advice is to
      always take a warm jumper since the weather can change fast in the
      mountains.
    </p>

    <h3>Hiking</h3>
    <div class="text-plus-image">
      <div class="text">
        <p>
          There are several hikes which start in Nendaz or in the immediate
          vicinity. Famous are the hikes along the bises ( the old waterways to
          water the meadows). You can find a hiking map at the tourist office.
        </p>
        <p>
          A longer hike with beautiful views is the hike to Dent the Nendaz.
          Take the cable car to Tracouet and walk all the way to the Dent. From
          there you can follow the hiking path along the ridge all the way to
          Plan the Fou above Siviez. Not recommended for young children! You can
          descend to Siviez and take the free Bus back to Nendaz. Or descent via
          a dirt track back to Tracouet or even to Nendaz.
        </p>
      </div>
      <figure>
        <img src="../../assets/images/nendaz-hiking.jpg" alt="Nendaz hiking" />
      </figure>
    </div>
    <h3>Mountain biking and e-biking</h3>
    <p>
      Nendaz and Quatre valley is a wonderful terrain for mountain biking. You
      can rent a bike in Nendaz village for example at On The Mountain, just
      under the cable car of Tracouet.
    </p>
    <p>
      The Mont-Fort tour (easy). Each year we love to take our bikes up to
      Tracouet and follow the trail to Siviez, take the Montfort cable car to
      the glacier above Verbier and descent along the (ski) slopes to Verbier,
      ascent to Savoleyres by cable car and continue your biking downhill to la
      Tzoumaz and Nendaz village.
    </p>
    <p>
      There are up to 200 km of biking trails. You can also rent an
      e-mountain-bike!
    </p>
    <h3>Golf</h3>
    <p>
      Three challenging golf courses are in the vicinity of Nendaz: Golf club
      Crans-Montana; 27 holes
    </p>
    <ul>
      <li>Golf club de Sion - 18 holes</li>
      <li>Golf club Verbier - 36 holes</li>
      <li>Golf club Crans-Montana - 27 holes</li>
    </ul>
    <h3>Swimming</h3>
    <p>
      Finally Nendaz has a lovely swimming pool with a nice view over the Rhone
      Valley. The pool is heated at 25ºC so that the temperature is always good.
    </p>
  </section>
</template>

<style scoped>
section {
  width: 100%;
}

.text-plus-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

figure {
  margin-left: 20px;
}

img {
  float: right;
  max-height: 100%;
  max-width: 100%;
}
</style>
