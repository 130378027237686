<template>
  <h2>Activities</h2>
  <p>
    There are many activities to do around the chalet. In the summer for
    instance, you could go hiking or mountain biking. If that isn’t enough there
    are options to go barbecuing or even to play golf.
  </p>
  <p>
    In the winter you can always go either skiing or snowboarding. Want to try
    something else? Go ski-touring or enjoy the lovely spa that Nendaz has to
    offer.
  </p>
  <p>
    Want to know more? Click one of the buttons below to get more information
    about summer or winter activities!
  </p>
  <div class="buttons">
    <base-button link :to="summerActivities">Summer activities</base-button>
    <base-button link :to="winterActivities">Winter activities</base-button>
  </div>
</template>

<script>
export default {
  computed: {
    summerActivities() {
      return this.$route.path + "/summer";
    },
    winterActivities() {
      return this.$route.path + "/winter";
    },
  },
};
</script>

<style scoped>
.buttons {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}
</style>
