<template>
  <h3>The location of the chalet on Google Maps</h3>

  <div id="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22101.655996919773!2d7.266545538567908!3d46.17648797911585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDbCsDEwJzM1LjQiTiA3wrAxNycwMi42IkU!5e0!3m2!1snl!2snl!4v1621335014073!5m2!1snl!2snl"
      width="100%"
      height="450"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>

<style scoped>
#map {
  width: 100%;
}
</style>
