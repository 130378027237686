<template>
  <the-header></the-header>
  <router-view></router-view>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";

export default {
  components: {
    TheHeader,
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/home");
      }
    },
  },
  created() {
    this.$store.dispatch("auth/autoLogin");
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

p,
a {
  padding: 0;
  margin: 0;
}

figure,
img {
  padding: 0;
  margin: 0;
}

body {
  width: 80%;
  margin: 0 auto 50px auto;
  background-color: #f2f2f2;
  color: #183152;
  line-height: 1.3;
}
</style>
