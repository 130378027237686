<template>
  <div>
    <base-dialog
      :show="error != null"
      @close="handleError"
      title="An error occured"
      >{{ error }}</base-dialog
    >
    <base-dialog :show="isLoading" fixed title="Authenticating...">
    </base-dialog>
    <h2>Log in or sign up</h2>
    <form class="loginForm" @submit.prevent="submitForm">
      <div class="form-item" v-if="!loginForm">
        <label for="firstName">First Name</label>
        <input type="firstName" id="firstName" v-model.trim="firstName" />
      </div>
      <div class="form-item">
        <label for="email">Email</label>
        <input type="email" id="email" v-model.trim="email" />
      </div>
      <div class="form-item">
        <label for="password">Password</label>
        <input
          type="password"
          id="password"
          v-model.trim="password"
          v-on:change="passwordsMatch = true"
        />
      </div>
      <div class="form-item" v-if="!loginForm">
        <label for="confirmPassword">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          v-model.trim="confirmPassword"
          v-on:change="passwordsMatch = true"
        />
      </div>
      <p v-if="!formIsValid">
        Please enter a valid email and password. Passwords must be at least 8
        characters long.
      </p>
      <p v-if="!passwordsMatch">Passwords don't match.</p>
      <base-button class="form-button">{{ submitButtonCaption }}</base-button>
      <base-button class="form-button" type="button" mode="flat" @click="switchAuthMode">{{
        switchModeButtonCaption
      }}</base-button>
    </form>
    <!-- <base-button @click="temporaryLogin"
      >Temporary login for gijs.buis@me.com</base-button
    > -->
  </div>
</template>

<style scoped>
.loginForm {
  width: 100%;
}

.form-item {
  display: flex;
  margin: 10px;
}

label {
  width: 150px;
}

.form-button {
  margin: 10px;
}
</style>

<script>
export default {
  data() {
    return {
      BaseButtonfirstName: "",
      email: "",
      password: "",
      confirmPassword: "",
      passwordsMatch: true,
      formIsValid: true,
      mode: "login",
      isLoading: false,
      error: null,
    };
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === "login") {
        return "Login";
      } else {
        return "Sign up";
      }
    },
    switchModeButtonCaption() {
      if (this.mode === "login") {
        return "Sign up instead";
      } else {
        return "Login instead";
      }
    },
    loginForm() {
      if (this.mode === "login") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    temporaryLogin() {
      let payload = {
        email: "gijs.buis@me.com",
        password: "123123123",
      };
      this.$store.dispatch("auth/login", payload);
    },
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 8
      ) {
        this.formIsValid = false;
        return;
      }
      if (this.mode === "signup" && this.password != this.confirmPassword) {
        this.passwordsMatch = false;
        return;
      }

      this.isLoading = true;

      const formPayload = {
        name: this.firstName,
        email: this.email,
        password: this.password,
      };

      try {
        if (this.mode === "login") {
          await this.$store.dispatch("auth/login", formPayload);
        } else {
          await this.$store.dispatch("auth/signup", formPayload);
        }
        // const redirectUrl = "/" + this.$route.query.redirect || "booking";
        const redirectUrl = "/mimique";
        this.$router.replace(redirectUrl);
      } catch (error) {
        this.error = error.message || "Failed to authenticate, try again later";
      }
      this.isLoading = false;
    },
    switchAuthMode() {
      if (this.mode === "login") {
        this.mode = "signup";
      } else {
        this.mode = "login";
      }
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>
