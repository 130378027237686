import { createApp } from "vue";
import App from "./App.vue";

import store from "./store/index.js";
import router from "./router.js";

import BaseButton from "./components/ui/BaseButton.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import HorizontalLine from "./components/ui/HorizontalLine.vue";
import GoogleMaps from "./components/pages/GoogleMaps.vue";
import InstagramFeed from "./components/pages/InstagramFeed.vue";

const app = createApp(App);

app.use(store);
app.use(router);

app.component("base-button", BaseButton);
app.component("base-dialog", BaseDialog);
app.component("horizontal-line", HorizontalLine);
app.component("google-maps", GoogleMaps);
app.component("instagram-feed", InstagramFeed);

app.mount("#app");
