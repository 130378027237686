import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";

const store = createStore({
  modules: {
    auth: authModule,
  },
  state() {
    return {
      navigation: [
        {
          id: 0,
          title: "Home",
          page: "/home",
        },
        {
          id: 1,
          title: "Activities",
          page: "/activities",
          subNavigation: [
            {
              id: 0,
              title: "Summer",
              page: "/activities/summer",
            },
            {
              id: 0,
              title: "Winter",
              page: "/activities/winter",
            },
          ],
        },
        {
          id: 2,
          title: "Restaurants",
          page: "/restaurants",
        },
        {
          id: 3,
          title: "Ski area",
          page: "/ski-area",
        },
        {
          id: 4,
          title: "Photo gallery",
          page: "/photo-gallery",
        },
        {
          id: 5,
          title: "Mimique",
          page: "/mimique",
        },
      ],
      further_information: [
        {
          id: 0,
          img: "mimique-summer",
          img_alt: "",
          text: "Summer",
          link: "/activities/summer"
        },
        {
          id: 1,
          img: "mimique-winter",
          img_alt: "",
          text: "Winter",
          link: "/activities/winter"
        },
        {
          id: 2,
          img: "mimique-skiing",
          img_alt: "",
          text: "Skiing",
          link: "/ski-area"
        },
        {
          id: 3,
          img: "mimique-tacos",
          img_alt: "",
          text: "Restaurants",
          link: "/restaurants"
        },
        {
          id: 4,
          img: "mimique-home",
          img_alt: "Photo Gallery",
          text: "Photo Gallery",
          link: "/photo-gallery"
        },
        {
          id: 5,
          img: "mimique-logo",
          img_alt: "",
          text: "Mimique",
          link: "/mimique"
        },
      ]
    };
  },
  getters: {
    navigation(state) {
      return state.navigation;
    },
    further_information(state) {
      return state.further_information;
    }
  },
});

export default store;
