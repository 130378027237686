<template>
  <div v-if="isUser">
    <h2>Booking</h2>
  </div>
  <div v-else>
    <h2>Please log in or sign up to see the bookings</h2>
    <router-link to="/auth">Go to log in/sign up page</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    bookings() {
      return this.$store.getters["bookings/getBookings"];
    },
    isUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>
