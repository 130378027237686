<template>
  <h2>Ski area</h2>
  <figure>
    <img
      src="../../assets/images/4-vallees-skiarea.png"
      alt="Ski area of Quatre Vallees"
      class="ski-area"
    />
  </figure>
  <h3>Lifts open in the summer</h3>
  <p>
    Around the middle of June some lifts will open, Mont-fort opens in the
    beginning of July. There is no possibility of skiing in Summer
    unfortunately, but there are enough out- and in-door sports facilities. Have
    you ever thought about Climbing? Trail-running or mountain-biking? It is
    even possible to hire an electric mountain bike! Hiking in spring is
    perfect, the last snow is melting and a lot of wild-life is coming out of
    their winter hiding places. Since there are not many tourists you can spot
    them easily.
  </p>
  <h3>Lifts open in the winter</h3>
  <p>
    Depending on the snowfall in autumn the first lift opens in the beginning of
    december and they stay open until mid or end of april. The new cable-car
    between Prarion and Siviez opens the possibility to ski on the gletcher of
    Mont-fort in 45-60 minutes after leaving the chalet on skis! The capacity is
    enormous so there are no waiting lines at that lift. And in between you have
    some of the greatest slopes to start your skiing day ( Prarion; blue/red)
    and Plan de Fou to Siviez ( red/blue) Both the slopes have the possibility
    of snow machines so this route is always open. Siviez is in the middle of
    the Quatre Vallees skiing area. There are some nice slopes when you like to
    stay there or you can make a tour to Veysonnaz or Verbier. There are two
    kinds of ski-passes, the Printze pass ( Nendaz, Siviez and Veysonnaz) or the
    Quatre Vallees pass with the possibility to also ski to and in Verbier. You
    can also buy a supplement for Verbier for just one day. Tracouet, Veysonnaz
    and lower Siviez is very easy, so a lot of lessons start there.
  </p>
  <h3>Ski from/to chalet</h3>
  <p>
    We recommend you start your skiing by walking behind the chalet in the
    corner of the road. There is a small wooden bridge you can cross and you can
    follow the road up ( 200 meters) to the ski-slope. Very good for the warming
    up of your muscles. You can of-course also walk up the slope behind the
    chalet, but the snow is rather deep.
  </p>
  <p>
    Returning to the chalet Mimique is easy: Restaurant les Cleves is situated
    at the ski slope coming down from Tracouet. Behind the restaurant you slide
    down the road you used in the morning to start. Or for the better skiers ski
    between the houses above the chalet Mimique and end in the backyard.
  </p>
  <h3>Keep track of skiing</h3>
  <div class="text-plus-image">
    <div class="text">
      <p>
        There are many apps to keep track of your skiing activities. One of them
        is Snoww, which you can install on your phone and even Apple Watch. This
        app allows you to keep track of your distance, time, current and max
        speed throughout different runs. There is also an option to add friends
        and keep track of their location (as long as they’ve approved the app to
        use their location).
      </p>
    </div>
    <figure>
      <img class="snoww" src="../../assets/icons/snoww.webp" alt="Snoww app" />
    </figure>
  </div>
</template>

<style scoped>
.ski-area {
  width: 100%;
}
.text-plus-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 300px;
}

.snoww {
  border-radius: 25px;
  height: 150px;
}
</style>
