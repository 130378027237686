<template>
  <h2>Chalet Mimique</h2>
  <div class="text-image">
    <div class="text">
      <p>
        Chalet Mimique was built in 2005, the basement was enlarged in 2014. The
        design combines the classic Swiss chalet-style with large windows. As a
        consequence the inside atmosphere is very light.
      </p>
      <p>
        On altitude 1.410 meters you can oversee the Rhone valley from Martigny
        to the Aletsch region. And situated at the end of a dead-end street
        almost there is no traffic.
      </p>
      <p>
        A heat pump provides the chalet with comfortable warmth (floor heating
        in all rooms) and in summer you could choose to use the top-cooling. But
        because of the height on the mountain nights are cool so probably you
        don’t need it.
      </p>
      <hr>
      <base-button id="redirectButton" @click="redirect">See availability</base-button>
    </div>
    <img
      class="mimique-balcony"
      src="../../assets/images/mimique-snow-balcony-view.jpg"
      alt="View onto the Mimique Balcony in the winter"
    />
  </div>
  <horizontal-line></horizontal-line>
  <div class="highlights">
    <section
      class="highlight"
      v-for="highlight in highlights"
      :key="highlight.id"
    >
      <img
        class="highlight-icon"
        :src="require('../../assets/icons/' + highlight.icon + '.png')"
        alt=""
      />
      <p class="highlight-text">{{ highlight.text }}</p>
    </section>
  </div>
  <horizontal-line></horizontal-line>
  <div class="webcam-feed"></div>
  <google-maps></google-maps>
  <horizontal-line></horizontal-line>
  <div class="google-maps"></div>
  <div class="logout">
    <p class="disclaimer">
      Disclaimer: We are in no way partnered with any of the restaurants we
      recommend on this page.
    </p>
  </div>
</template>

<script>
import BaseButton from '../ui/BaseButton.vue';
export default {
  components: { BaseButton },
  data() {
    return {
      highlights: [
        {
          id: 0,
          text: "Sauna available",
          icon: "sauna",
        },
        {
          id: 1,
          text: "Washing machine and dryer ready to use",
          icon: "washing",
        },
        {
          id: 2,
          text: "3 bathrooms, one with a tub",
          icon: "bath",
        },
        {
          id: 3,
          text:
            "Snowfree parking in the garage and under the carport. Another 2 parking lots are just in front of the chalet For your EV there is a charger (3.7 kW, Chademo)",
          icon: "charged-parking",
        },
        {
          id: 4,
          text:
            "Mimique has 4 bedrooms (3 x with double beds and 1x2 separate beds)",
          icon: "bed",
        },
        {
          id: 5,
          text:
            "The living room has a TV set (cable and satellite), CD/DVD player and a Bluetooth audio-receiver. The sleeping room in the basement also offers a TV set WiFi available, broadband internet connection (up to 70 Mbps)",
          icon: "television",
        },
      ],
    };
  },
  computed: {
    isUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.replace("/home");
    },
    redirect() {
      window.open("https://www.interhome.com/switzerland/valais/nendaz/holiday-house-mimique-ch1961.20.2/");
    }
  },
};
</script>

<style scoped>
.text-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
}

.text {
  display: flex;
  flex-direction: column;
  width: 70%;
}

#redirectButton {
  width: 100%;
}

.mimique-balcony {
  width: 300px;
  height: auto;
  margin-left: 20px;
}

.highlights {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.highlight {
  text-align: center;
}

.highlight-icon {
  height: 80px;
}

.logout {
  text-align: center;
}

.disclaimer {
  padding-bottom: 20px;
}
</style>
