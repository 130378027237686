<template>
  <div class="photo-gallery">
    <figure class="snow">
      <img src="../../assets/images/gallery/mimique-snow.jpg" alt="" />
    </figure>
    <figure class="view">
      <img src="../../assets/images/gallery/mimique-view.jpg" alt="" />
    </figure>
    <figure class="logo">
      <img src="../../assets/images/gallery/mimique-logo.jpg" alt="" />
    </figure>
    <figure class="winter-tree">
      <img src="../../assets/images/gallery/mimique-winter-tree.jpg" alt="" />
    </figure>
    <figure class="snow-backyard">
      <img src="../../assets/images/gallery/mimique-snow-backyard.jpg" alt="" />
    </figure>
    <figure class="basement">
      <img src="../../assets/images/gallery/mimique-basement.jpg" alt="" />
    </figure>
    <figure class="livingroom">
      <img src="../../assets/images/gallery/mimique-livingroom.jpg" alt="" />
    </figure>
    <figure class="basement-room">
      <img src="../../assets/images/gallery/mimique-basement-room.jpg" alt="" />
    </figure>
    <figure class="summer">
      <img src="../../assets/images/gallery/mimique-summer.jpg" alt="" />
    </figure>
    <figure class="summer-balcony">
      <img src="../../assets/images/gallery/mimique-summer-balcony.jpg" alt="" />
    </figure>
    <figure class="basement-bathroom">
      <img src="../../assets/images/gallery/mimique-basement-bathroom.jpg" alt="" />
    </figure>
    <figure class="main-bathroom">
      <img src="../../assets/images/gallery/mimique-main-bathroom.jpg" alt="" />
    </figure>
  </div>
</template>

<style scoped>
.photo-gallery {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: 2px solid black;
}

.snow {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.logo {
  grid-column: 3;
  grid-row: 2;
}

.winter-tree {
  grid-column: 4;
  grid-row: 1 / span 2;
}

.livingroom {
  grid-column: 2 / span 2;
  grid-row: 3 / span 2;
}

.basement {
  grid-column: 1;
  grid-row: 3 / span 2;
}

.snow-backyard {
  grid-column: 4;
  grid-row: 3;
}

.basement-room {
  grid-column: 4;
  grid-row: 4;
}

.summer {
  grid-column: 1;
  grid-row: 5;
}

.summer-balcony {
  grid-column: 2;
  grid-row: 5;
}

.basement-bathroom {
  grid-column: 3;
  grid-row: 5;
}

.main-bathroom {
  grid-column: 4;
  grid-row: 5;
}
</style>
