import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./components/pages/HomePage.vue";
import ActivitiesList from "./components/pages/ActivitiesList.vue";
import ActivitiesMain from "./components/pages/ActivitiesMain.vue";
import ActivitiesSummer from "./components/pages/ActivitiesSummer.vue";
import ActivitiesWinter from "./components/pages/ActivitiesWinter.vue";
import RestaurantsList from "./components/pages/RestaurantsList.vue";
import SkiArea from "./components/pages/SkiArea.vue";
import PhotoGallery from "./components/pages/PhotoGallery.vue";
import ChaletMimique from "./components/pages/ChaletMimique.vue";
import BookingSystem from "./components/pages/BookingSystem.vue";
import UserAuth from "./components/pages/UserAuth.vue";
import NotFound from "./components/pages/NotFound.vue";

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/home", component: HomePage },
    {
      path: "/activities",
      component: ActivitiesList,
      children: [
        { path: "", component: ActivitiesMain },
        { path: "summer", component: ActivitiesSummer },
        { path: "winter", component: ActivitiesWinter },
      ],
    },
    { path: "/restaurants", component: RestaurantsList },
    { path: "/ski-area", component: SkiArea },
    { path: "/photo-gallery", component: PhotoGallery },
    { path: "/mimique", component: ChaletMimique },
    { path: "/booking", component: BookingSystem },
    { path: "/auth", component: UserAuth },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

export default router;

<div class="dropdown navigation">
  <button class="dropbtn navigation-item">Dropdown</button>
  <div class="dropdown-content navigation-dropdown">
    <a class="dropdown-item" href="#">
      Link 1
    </a>
    <a class="dropdown-item" href="#">
      Link 2
    </a>
    <a class="dropdown-item" href="#">
      Link 3
    </a>
  </div>
</div>;
