<template>
  <h2>Winter activities</h2>
  <div class="text-plus-image">
    <div class="text">
      <p>
        At 1500 meters high, the snow conditions around the chalet is often
        great for hiking, sledging in the back yard or cooling off after using
        the sauna. Children love making ice castles or snow bars for their
        parents. The BBQ is even in the winter available.
      </p>
      <p>
        After snowfall the road is cleaned once or twice a day; but still you
        need winter tires and chains or a 4x4 with winter tires ( no all weather
        tires!).
      </p>
      <p>
        From the end of January the sun climbs high enough to rise over the
        Mountain of Tracouet so you can enjoy the sun on the terrace.
      </p>
      <p>
        For skiing you can gear up in the warmth of the chalet and walk to the
        piste, it is a small climb to the restaurant of Les Cleves, but that
        means you've already done your warming up for a lovely day of skiing.
        When you return, you can either return on skis ( for good skiers) or you
        can slide down the same route you used in the morning, after a nice
        drink at Le BOB on the route home.
      </p>
      <p>
        Wood for the wood stove is always available. Don’t fill it with too much
        wood; you will melt from the warmth it generates.
      </p>
      <p>
        The Swiss are famous for their Cheese fondue; two caquelons are
        available; one is electric , the other uses burning gel which usually is
        in stock.
      </p>
      <p>
        Unfortunately Interhomes policy is not to leave any dry food in the
        chalet so don’t forget your rice, pasta, tea or coffee for the first
        evening.
      </p>
      <p>
        The Coop, which is a supermarket, is well stocked and you can reach it
        by foot (900 m) or by car. A bakery, which opens at 06.00, and a butcher
        are also within walking distance (900m).
      </p>
    </div>
    <figure>
      <img
        src="../../assets/images/mimique-winter.jpg"
        alt="Mimique in summer"
      />
    </figure>
  </div>
  <h3>Ski and snowboard</h3>
  <p>
    The Ski-area has 412 km of pistes and is one of the largest in Switzerland.
    As beginner you can find some easy slopes above Nendaz. You can take the
    cable car to Tracouet and get skying lessons on the sunny pistes.
  </p>
  <p>
    For more advanced skiers Quatre Vallées is one big treat. With fun-parks, a
    lot of Yellow slopes ( not prepared, often moguls and up to 1000 m long!)
    and ski-routes for full day skiing from Veysonnaz all the way to Verbier and
    Le Chables.
  </p>
  <p>
    Quatre Vallees and especially Verbier and Mont-fort area is famous for
    off-piste skiing and the Freeride World Tour in march/ february.
  </p>
  <h3>Ski-touring</h3>
  <p>
    There is an emerging trend for Ski touring in the area. Nendaz has five
    itineraries of ski-touring , two from Nendaz, and three from Siviez, all not
    too difficult. Don’t forget to pack some light all-weather pants and
    jumpers. You will get warm after the first few minutes of climbing on the
    ski’s. But while coming down on the ski slopes you'll most likely need a
    warm jacket (dependable on the season and weather of course).
  </p>
  <h3>Hiking</h3>
  <p>
    Nendaz preparates and flattens several Winter-hiking trails so the
    pedestrians can make nice walks in the snow. You will not need the typical
    snow-shoes but good waterproof walking shoes will be necessary on these
    trails.
  </p>
  <h3>Spa and swimming pool</h3>
  <p>
    When the snow is falling, swimming in the out-door warm swimming pool of Spa
    the Bisses is a fairy-tale coming true. You can lay on the under-water beds
    and try catching the snowflakes in your mouth.
  </p>
  <p>
    They have 7 different saunas and grottoes next to pools with moving current,
    and a jacuzzi.
  </p>
  <p>
    You can relax your muscles tired of skiing days on sunny days as well. The
    spa is located 800 meters from Mimique, when you take the elevator train
    between the cable car station and Nendaz centre (Coop and grand square).
    Alternatively you can take the car and use the parking under the spa.
  </p>
</template>

<style scoped>

.text-plus-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

figure {
  margin-left: 20px;
}

img {
  float: right;
  max-height: 100%;
  max-width: 100%;
}
</style>
